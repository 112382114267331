import React, { useState, useEffect } from 'react';
import './StorefrontBanner.scss';

const StorefrontBanner = (props) => {
    const [ data, setData ] = useState({});
    const storefrontTitle = props.title;

    const storefrontUrl = process.env.REACT_APP_API_STOREFRONT_STORAGE;

    useEffect( () => {
        setData(props.item);
    }, [props.item]);

    return (
        <div className={`col-md-${data.storefront_width} storefront-banner-container`}>
            <div className="storefront-content">
                <h2 className='storefront-title'> { storefrontTitle } </h2>
                {/* Button */}
                <a className='storefront-button' href={`/category?strfrnt=${data.id}`}> Shop Now </a>
            </div>
            <img className='storefront-banner' src={ `${storefrontUrl}${data.storefront_banner}` } />
        </div>
    );
}

export default StorefrontBanner;